import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  PromotionNotAppliedWarning,
  useApplyPromoCodeMutation,
  useGetDiscountFromMarketingCodeQuery,
  useRemovePromoCodeMutation,
  YumCart
} from '@pizza-hut-us-development/client-core';
import { CouponReturn } from '../types';
import { YUM_COUPON_ERROR_MESSAGE } from '@/clientCore/cart/constants';
import { closeCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import Routes from '@/router/routes';
import { getAlphanumericValue } from '@/clientCore/cart/helpers';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import YumAddedCoupons from '../../YumAddedCoupons/YumAddedCoupons';
import triggerMutationWrapper from '@/clientCore/helper/triggerMutationWrapper';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { findRedemptionWarningFromPromoOrCode, isRedemptionWarning, isYumCart } from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/helpers';
import useCodeRedemption from '../../YumAddedCoupons/hooks/useCodeRedemption';
import { getOrInitializeOptimizely } from '../../../../../../../../../../optimizely/optimizely';

const useCoupon = (): CouponReturn => {
  const cart = useSelector(orderSelectors.cart);
  const storeDetails = useSelector(localizationSelectors.storeDetails);

  const [handleRedemptionWarnings] = useCodeRedemption();

  const [couponId, setCouponId] = useState('');
  const [couponLoading, setCouponLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(((cart as YumCart)?.appliedPromotions?.length ?? 0) > 0);
  const [errorMessage, setErrorMessage] = useState('');
  const [marketingCode, setMarketingCode] = useState<string>('');

  const dispatch = useDispatch();
  const router = useRouter();

  const [applyYumPromoCode] = useApplyPromoCodeMutation();
  const [removePromoCode] = useRemovePromoCodeMutation();
  const {
    data: discountCodeData, isFetching, error: discountCodeError
  } = useGetDiscountFromMarketingCodeQuery(
    { storeNumber: storeDetails?.storeNumber, marketingCode },
    { skip: !storeDetails?.storeNumber || !marketingCode }
  );

  const sanitizeCouponId = (coupon: string) => getAlphanumericValue(coupon);

  const sanitizeAndSetCouponId = (coupon: string) => {
    setErrorMessage('');
    setCouponId(sanitizeCouponId(coupon));
  };
  const submitYumPromoCode = async () => {
    if (!couponId) {
      return;
    }
    const trimmedCouponId = couponId.toUpperCase().trim();
    setCouponLoading(true);
    await triggerMutationWrapper(
      applyYumPromoCode(trimmedCouponId), {
        onSuccess: (responseCart) => {
          if (isYumCart(responseCart)) {
            const isCouponApplied = responseCart?.appliedPromotions?.some((promotion) => promotion?.code === trimmedCouponId);
            const associatedWarning = findRedemptionWarningFromPromoOrCode(responseCart, undefined, trimmedCouponId);

            if (!isCouponApplied && !associatedWarning) setErrorMessage(YUM_COUPON_ERROR_MESSAGE);
            if (associatedWarning) handleRedemptionWarnings(associatedWarning.promotionId, responseCart);
          }
          setCouponLoading(false);
        },
        onError: (error) => {
          setErrorMessage(YUM_COUPON_ERROR_MESSAGE);
          console.error({ error });
          setCouponLoading(false);
        }
      }
    );
  };

  const checkYumCouponRouting = () => {
    if (!couponId) {
      setErrorMessage(YUM_COUPON_ERROR_MESSAGE);
      return;
    }
    setMarketingCode(couponId.toUpperCase());
  };

  const handleRemoveYumPromoCode = async (code: string) => {
    try {
      await removePromoCode(code);
    } catch (error) {
      console.error({ error });
    }
  };

  const appliedPromotions = useMemo(() => (cart as YumCart)?.appliedPromotions || [], [cart]);

  const redemptionWarnings = useMemo(() => (cart as YumCart)?.warnings?.reduce((acc: PromotionNotAppliedWarning[], warning) => {
    const optimizely = getOrInitializeOptimizely();
    const isLoyaltyWarningFixEnabled = optimizely?.isFeatureEnabled('fr-web-4636-fix-loyalty-redemption-warning');
    if (!isRedemptionWarning(warning)) return acc;
    const indexOfMatchingWarning = acc.findIndex((parsedWarning) => parsedWarning.promotionId === warning.promotionId);

    if (indexOfMatchingWarning === -1) return [...acc, warning];

    // Loyalty redemptions will return 2 warnings, the first has no reason data, so leverage the 2nd in this case
    const matchingWarningReason = acc[indexOfMatchingWarning].reasons[0];
    if (isLoyaltyWarningFixEnabled && matchingWarningReason?.message === 'PROMOTION_NOT_APPLIED' && !matchingWarningReason.__typename) {
      const newAcc = [...acc];
      newAcc[indexOfMatchingWarning] = warning;
      return newAcc;
    };

    return acc;
  }, []) || [], [cart]);

  useEffect(() => {
    if ((redemptionWarnings.length || appliedPromotions.length) && !isExpanded) {
      setIsExpanded(true);
    }
  }, [isExpanded, setIsExpanded, redemptionWarnings, appliedPromotions]);

  const yumDisplayableCoupons = [...appliedPromotions, ...redemptionWarnings].map((promotionOrWarning, index) => <YumAddedCoupons promotionOrWarning={promotionOrWarning} key={index} />);

  useEffect(() => {
    if (discountCodeError && !isFetching) {
      submitYumPromoCode();
      setMarketingCode('');
    }

    if (!isFetching && discountCodeData) {
      router.push({ pathname: Routes.DEALS, query: { id: marketingCode } });
      dispatch(closeCartRail());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCodeData, isFetching, discountCodeError, marketingCode]);

  return [
    {
      couponId,
      errorMessage,
      isExpanded,
      yumDisplayableCoupons,
      couponLoading
    },
    {
      applyButtonSubmit: checkYumCouponRouting,
      handleRemoveYumPromoCode,
      sanitizeAndSetCouponId,
      setIsExpanded
    }
  ];
};

export default useCoupon;
